//import Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400&family=Rufina:wght@700&family=Unna:wght@700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

$title-font-alt: "Rufina", serif;
$title-font: 'Unna', serif;
$title-weight: 700;
$p-font: "Oxygen", sans-serif;

$main: #35271C;
$secondary: #A29369;
$light:#EEE8D7;
$variant:#495B32;

$background-dark: #9AC7E5;

$padding-common: 3%;
$width-common: 430px;
$width-common-sm: 280px;
$circle-common: 550px;
$circle-common-sm: 350px;

body {
  font-family: $p-font;
  font-size: 18px;
  color: $main;
  background-color: $light;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: 100% $title-font-alt;
    padding: $padding-common;
    font-weight: $title-weight;
  }
  h1 {
    font-size: 7em;
    text-align: center;
  }
  h2 {
    font-size: 3.5em;
    text-align: center;
  }
  .home{
    color:$secondary !important;
    background-color: $light !important;
  }
  #logo{
    width:80%;
  }
  .centerDisplay{
    width: auto;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .bold{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: $title-weight;
  }
  .lightWeight{
    font-weight: 300;
    margin-bottom: 0;
    padding-top: 0;
  }
  .minDst{
    font-family: $title-font-alt;
    font-weight: 300;
    padding-top: 0;
  }
  #titleLogo{
    font-family: $title-font;
    color:$light;
    position: absolute;
    top: 2.75em;
    justify-self: center;
    z-index: 2;
  }
  #unqTitle{
    font-size: 2em;
    margin-top: 7em;
    margin-bottom: 3em;
    p{
      font-size: 0.5em;
      font-family: $p-font;
      padding-top: 0px;
      padding-right: 2em;
      float: right;
    }
  }
  h3 {
    font-size: 2em;
  }
  .paddingLeftNone{
    padding-left: 0%;
  }
  svg{
    position: absolute;
  }
  .container{
    margin-bottom: 5em;
  }
  @include media-breakpoint-down(md) {
    h1 {
      font-size: 4.2em;
    }
    #logo{
      width:60%;
      padding-bottom: .5em;
    }
    h2{
      font-size: 2.5em;
      text-align: left;
      padding-left: 0px;
    }
    #unqTitle{
      position: static;
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(sm) {
    h1{
      font-size: 2.4em;
    }
    h2{
      font-size: 2em;
    }
    h3 {
      font-size: 1.6em;
    }
    #unqTitle{
      margin-top: 2.5em;
      margin-bottom: 2.5em;
    }
  }
  section {
    padding-bottom: 4%;
  }
  p {
    font-size: 1.2em;
    padding-top: 1%;
    color: $main;
  }
  @include media-breakpoint-down(sm) {
    #avatar{
      width:25%;
    }
  }
  .figure-caption {
    font-size: 1.2em;
    // a {
    //   color: $secondary;
    //   text-decoration-style: dotted;
    // }
    // a:hover {
    //   color: $secondary;
    // }
  }

  .backtoTop{
    position: fixed;
    bottom: 10em;
    right: 0;
    background: $secondary;
    color: $light;
    border: none;
    border-radius: 8px 0px 0px 8px;
    padding: 12px;
    opacity: 50%;
    .material-symbols-outlined {
      font-size: 3em;
      font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 40
    }
  }
  .backtoTop:hover{
    background: $main;
    cursor: pointer;
    opacity: 100%;
  }

  .resume-header{
    h1{
      padding-bottom: 1%;
    }
  }

  .resume{ 
    display: grid;
    grid-template-columns: 2fr 3fr;
    row-gap:20px;
    margin-top: 2em;
    margin-left: 15%;
    margin-right: 15%;
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-left: 10%;
      margin-right: 10%;
    }
    @include media-breakpoint-down(sm) {
      display: block;
      margin-left: 5%;
      margin-right: 5%;
    }
    .grid1 {
      grid-column: 1 / 2; 
      grid-row: 1; 
    }
    .grid2 {
      grid-column: 2 / 3; 
      grid-row: 1; 
    }
    .grid3 {
      grid-column: 1 / 3; 
      grid-row: 2 ; 
    }
    h2{
      font-size: 2em;
      text-align: start;
      padding-bottom: 10px;
      padding-left: 0;
      padding-top: 0;
    }
    section{
      padding-bottom: 1.5em;
    }
    h3{
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    li{
      font-size: 1.15em;
    }
    .skills-section{
      .gridLayout{
        display: grid;
        column-gap:20px;
        grid-template-columns: 1fr 2fr;
      }
      @include media-breakpoint-down(md) {
        .gridLayout{
          display: block;
        }
      }
      li{
        padding-right: .75em;
      }
    }
    .education-section{
      @extend.skills-section;
      .gridLayout{
        grid-template-columns: 1fr 1fr 1fr;
      }
      h3{
        font-size: 1.25em;
      }
      p{
        padding-top: 0;
        margin-bottom: 0;
      }
    }
    .experience-section{
      h3{
        font-size: 1.25em;
      }
    }
  }
}

//navigation bar
.container-fluid {
  align-items: center;
  padding: 1%;
  font-size: 24px;
}

.homNav{
  @extend .home;
}
.nav{
  .nav-item{
    color:$main !important;
    font-size: 36px;
    font-weight: 400;
  }
  .nav-link:hover{
    text-decoration-line: underline;
    text-decoration-thickness: .15em;
  }
}

.landingPage {
  background-color: $light;
  margin-bottom: 0px;
  padding: 0px;
  .wideImage{
    width: 100vw;
    height: 80vh;
    object-fit: cover;
  }
  @include media-breakpoint-down(md) {
    .wideImage{
      height: 55vh;
    }
  }
  @include media-breakpoint-down(sm) {
    .wideImage{
      height: 35vh;
    }
  }
  // #darkNav{
  //   border-bottom: solid;  
  //   .nav-link{
  //     color:$secondary;
  //     font-size: 1em;
  //     padding:1em;
  //   }
  // }
}

#showCase{
  margin-top: 9em;
  @include media-breakpoint-down(sm) {
    margin-top: 3em;
  }
  #hltWork{
    margin-top: 3%;
    display: flex;
    overflow-x: scroll;
    background-color: #DED6BE;
    .selected {
      transform: scale(1);
      opacity: 1;
    }  
    .not-selected {
      transform: scale(.75);
      filter: blur(2px);
      opacity: 0.5;
    }
    .card{
      margin-top: 2em;
      transition: transform 0.3s ease, opacity 0.3s ease;
      background-color: #DED6BE;
      border-style: none;
        #collapse-content{
          background-color: $secondary;
          color:$main;
          p{
            color:$main;
            font-size: 1.15em;
          }
          h5{
            padding: 2% 0 2% 0;
            font-size: 30px;
          }
        }  
      .tag{
        display: inline-block;
        padding: 2px 10px 2px 10px;
        text-align: center;
        border-style: solid;
        border-color: $main;
        color: $main;
        border-radius: 15px;
        margin-right: .5em;
        margin-bottom: .5em;
      }
    }
  }
}

.portfolioSection{
  img{
    width: 100%;
  }
  .btn{
    color: $light;
    background-color: $secondary;
    border-color: $secondary;
  }
  .btn:active{
    color: $light;
    background-color: $main;
    border-color: $main;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: 1fr 2fr;
    grid-gap: 10px; 
  } 
  section {
    height: 80vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    scroll-snap-align: start;
    perspective: 300px;
    padding-bottom: 0;
  }
  section > div {
    width: 100vw;
    height: 500px;
    margin: 20px;
    padding-right: 2em;
    position: relative;
    overflow: hidden;
  }
  @include media-breakpoint-down(lg) {
    .image-grid {
      grid-template-columns: repeat(2, 1fr); 
    }
    section{
      display: block;
    }
  }
  @include media-breakpoint-down(sm) {
    h2{
      padding-left: .35em;
    }
    .image-grid {
      display: block;
      img{
        padding-top: 10px;
      }
    }
    section{
      display: block;
      height: auto;
      margin-bottom: 3em;
    }
    section > div {
      display: block;
      height: auto;
    }
    .btn{
      margin-top: 1em;
      margin-left: 1em;
    }
  }
}

.light-background {
  padding-top: 0;
  .container{
    margin-bottom: 0;
  }
  h2 {
    color: $main;
  }
  h3 {
    margin-top: 3em;
    text-align: center;
    font-size: 2.5rem;
    color: $main; 
    img{
      padding-right: 2%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.8em;
    }
  }
  .skillSection{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 0;
    .row{
      padding-top: 20px;
      .figure{
        padding-right: 2em;
      }
    }
    h3 {
      margin-top: 0;
      padding-bottom: 10px;
      font-family: $p-font;
      font-weight: 300;
      font-size: 5em;
    }
    @include media-breakpoint-down(sm) {
      text-align: left;
      h3{
        font-size: 3.6em;
      }
      .col{
        display: grid;
        .figure{
          justify-self: center;
        }
      }
    }
  }

  .cover{
    width:260px;
  }
  @include media-breakpoint-down(sm) {
    h3 {
      margin-top: 1em;
      text-align: left;
      padding-left: 0%;
    }
  }
  .row {
    justify-content: flex-start;
    column-gap: 5%;
  }
  @include media-breakpoint-down(lg) {
    .row {
      column-gap: 100%;
    }
  }
}

.slides{
  h1{
    padding-bottom: 0;
  }
  h2{
    padding-top: 0;
    text-align: center;
  }
  .slideShowImg{
    display: grid;
    margin-bottom: 1em;
    a{
      display: grid;
      justify-content: center;
      padding-bottom: 1em;
      img{
        padding-bottom: 0;
      }
    }
    img{
      justify-self: center;
      width:60vw;
      padding-bottom: 1em;
    }
    video{
      justify-self: center;
      width:60vw;
      padding-bottom: 1em;
    }
    @include media-breakpoint-down(sm) {
      img{
        width:90vw;
      }
    }
  }
  
}

   /* The Modal (background) */
   .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 70%;
  }

  /* Caption of Modal Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: $light;
    padding: 10px 0;
    height: 150px;
  }

  /* Add Animation */
  .modal-content, #caption {  
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
  }

  @keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: $light;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }

footer{
  hr{
    border: none;
    height: 2px; 
    background-color: $main; 
  }
  .container{
    justify-content: space-around;
    padding-bottom: .7em;
    margin-bottom: 0px;
    a {
      color: $main;
    }
    .navbar-brand:hover{
      color: $main;
    }
    .bi {
      width: 1.2em;
      height: 1.2em;
      color:$main;
    }
    .sicons{
      text-decoration: none;
      padding-left: .5em;
      padding-right: 1.75em;
    }
    p{
      color: $main;
      font-weight: 300;
      padding: 0;
      margin: 0;
      text-align: center;
    }
    .nav-link {
      font-size: .75em;
      padding-top: 0%;
    }
    @include media-breakpoint-down(sm) {
      .nav-link {
        font-size: .7em;
      }
    }
    .nav-link:hover{
      color: $light;
      text-decoration: none;
    }
    #footLogo {
      display: flex;
      font-family: $title-font;
      font-size: 60px;
      text-align: center;
      padding-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
      #footLogo {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 48px;
      }
    }
  }
}